import axios from 'axios'

const api = axios.create({
 baseURL: 'https://vmsadmin.unitdtechnologies.com:3001',

});




export default api